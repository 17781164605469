<style lang="scss">
.email-box {
  p {
    display: block;
    min-height: 20px;
    line-height: 1.3rem;
  }
}
</style>
<template>
  <div>
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :redirect="redirectPath"
    />

    <v-video-modal
      name="overview"
      title="Email template"
      description="A video to help you get started."
      step="email-template"
      thumbnail="templates.png"
      embed="JVUwRSt1LNs"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />

    <email-signature-slide-in
      :show.sync="showEmailSlideIn"
      :signature.sync="signature"
      @close="hideSlideIn"
      @saved="saveSlideIn"
    />

    <div class="max-w-lg px-4 mx-auto ">
      <div class="flex -mx-2 mt-24 mb-10">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          id="lead-form-setting"
          class="w-full px-2"
        >
          <div class="bg-white px-8 py-8 rounded">
            <div class="flex items-center justify-between mb-2">
              <div class="w-1/2 md:w-2/3">
                <h1 class="text-xl">
                  Email template
                </h1>
              </div>
              <div class="w-1/2 md:w-1/3 text-right">
                <div class="inline-block">
                  <v-tutorial-button @click="showTutorialVideoModal"/>
                </div>
              </div>
            </div>
            <div class="input-group">
              <label>Template name</label>
              <input
                v-model="name"
                v-validate="{ required: true }"
                type="text"
                class="w-full"
                name="name"
              >
              <span class="error">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="bg-white mt-4 px-8 pt-8 pb-8">
            <!-- Language Switcher -->
            <div class="mb-3 flex flex-row justify-center md:justify-end items-center language-switcher">
              <span class="hidden sm:inline-block mr-3">Edit in</span>

              <div class="flex justify-end flex-row language-switcher__group">
                <div class="flex flex-col flex-1 md:hidden">
                  <div class="input-group">
                    <label>Edit in</label>
                    <select class="block form-control-sm form-select flex-1" v-model="selectedLanguage">
                      <template v-for="locale in activeLanguages">
                        <option :value="locale.code">{{ locale.code.toUpperCase() }}</option>
                      </template>
                    </select>
                  </div>
                </div>

                <template v-for="locale in activeLanguages">
                  <button
                    type="submit"
                    class="items-center bg-white p-3 text-grey-darker font-normal hidden md:flex md:flex-row"
                    :class="({
                      'active-language-button': selectedLanguage === locale.code,
                      'inactive-language-button': selectedLanguage !== locale.code,
                    })"
                    @click.prevent="selectedLanguage = locale.code"
                  >
                    <span
                      class="w-2 h-2 inline-flex rounded-full mr-2"
                      :class="({
                        'active-language-circle': content[locale.code] && subject[locale.code],
                        'inactive-language-circle': ! content[locale.code] || ! subject[locale.code],
                      })"
                    ></span> {{ locale.code.toUpperCase() }}
                  </button>
                </template>
              </div>
            </div>
            <!-- END: Language Switcher -->

            <div class="input-group">
              <label>Subject</label>
              <input
                v-model="subject[selectedLanguage]"
                v-validate="{ required: true }"
                type="text"
                class="w-full"
                name="subject"
              >
              <span class="error">{{ errors.first('subject') }}</span>
            </div>
            <div class="input-group">
              <label>Message</label>
            </div>
            <div class="mb-4">
              <template v-if="content">
                <v-ckeditor-token v-model="content[selectedLanguage]"/>
              </template>

              <div class="mt-2 error">
                {{ errors.first('content') }}
              </div>
            </div>
            <div>
              <label class="block mb-4">Preview email signature</label>
              <div
                class="w-full border border-grey h-32 rounded py-3 px-3 text-black overflow-auto email-box"
              >
                <div v-html="signature.content"/>
              </div>
              <a
                class="green underline cursor-pointer text-sm mt-3 block "
                @click="showSlideIn"
              >
                Edit your email signature
              </a>
            </div>
          </div>

          <div class="mt-5 mb-10 text-right">
            <v-button
              v-show="!isSaving"
              @click="save"
            >
              Save
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * External dependencies
 */
import filter from 'lodash.filter'

/**
 * Internal dependencies
 */
import auth from '@/auth'
import {logException} from '@/helpers'
import EmailSignatureSlideIn from './Extension/EmailSignatureSlideIn'

export default {
  components: {
    EmailSignatureSlideIn,
  },
  data() {
    return {
      metaTitle: 'Loading',
      isSaving: false,
      isSaved: false,
      loading: true,
      mailId: null,
      name: '',
      subject: {},
      content: {},
      signature: {
        content: '',
        updatePending: false,
      },
      user: auth.user(),
      redirectPath: '/templates/mails',
      showEmailSlideIn: false,
      isShowTutorialVideoModal: false,
      selectedLanguage: '',
    }
  },
  computed: {
    companyDetails() {
      return this.$store.getters['company/details']
    },

    activeLanguages() {
      return filter(this.companyDetails.locales, (locale) => locale.isActive)
    }
  },
  async mounted() {

    if (this.$route.query.project) {
      this.redirectPath = `/projects/${this.$route.query.project}`
    }

    await this.refresh()
    this.selectedLanguage = Object.values(this.companyDetails.locales).find(locale => locale.isDefault).code

    if (this.$route.params.templateId) {
      await this.getData(this.$route.params.templateId)
      this.metaTitle = `Template Mail: ${this.name}`
    } else {
      this.metaTitle = 'New Template Mail'
    }

    this.loading = false
  },
  methods: {
    showTutorialVideoModal() {
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal() {
      this.isShowTutorialVideoModal = false
    },
    async refresh() {
      try {
        await this.$store.dispatch('auth/refreshUser')
        const signature = this.$store.getters['auth/emailSignature']

        if (signature) {
          this.signature.content = signature
          this.signature.updatePending = true
        }
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    save() {
      this.$validator.validate().then(result => {
        if (result) {

          if (this.content == '') {
            this.$toasted.global.general_error({
              message: 'Email message cannot be empty.'
            })
            return
          }

          this.doSave()
        }
      })
    },
    async doSave() {
      this.saving = true
      try {
        const postData = {
          companyId: this.user.company.id,
          mailId: this.mailId,
          name: this.name,
          subject: this.subject,
          content: this.content,
        }

        const {data} = await this.$api.get('templateMail').updateOrCreate(postData)

        if (!this.mailId) {
          const postData = {
            entryType: 'add_first_email_template',
          }

          try {
            await this.$store.dispatch('company/gamification', postData)
          } catch (e) {
            this.$toasted.global.api_error(e)
          }
        }

        this.mailId = data.mail.id
        this.isSaved = true
        this.saving = false

        this.$toasted.global.general_success({
          message: 'Mail template updated.'
        })

      } catch (e) {
        logException(e)
      }
    },
    async getData(id) {
      try {
        const {data} = await this.$api.get('templateMail').detail(this.user.company.id, id)
        this.mailId = data.mail.id
        this.name = data.mail.name
        this.subject = data.mail.subject
        this.content = data.mail.content
      } catch (e) {
        logException(e)
      }
      this.loading = false
    },
    async saveSlideIn(signature) {
      await this.refresh()
      this.signature.updatePending = false
      this.showEmailSlideIn = false
    },
    async hideSlideIn() {
      this.signature.updatePending = false
      this.showEmailSlideIn = false
    },
    async showSlideIn() {
      this.signature.updatePending = false
      this.signature.updatePending = true
      this.showEmailSlideIn = true
    },
  },
}
</script>

<style lang="scss">
.language-switcher {
  > span {
    font-size: 14px;
  }

  .language-switcher__group {
    button {
      border-radius: 0 !important;
      border: 1px solid #d1d1d1;
      padding: 12px 16px;
      transition: all .2s ease-out;

      &:hover {
        opacity: .7;
        background: #f6f6f6;
      }

      &:first-child {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
      }

      &:last-child {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
      }
    }

    .active-language-button {
      background: white;
      border: 1px solid #666;
    }

    .inactive-language-button {
      background: #f6f6f6;
    }

    .active-language-circle {
      background: green;
    }

    .inactive-language-circle {
      background: #ce0f0f;
    }

    button + button {
      border-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .language-switcher__group {
    flex: 1;
  }
}
</style>
